import moment from 'moment';
import swal from 'sweetalert';

export default class Setting {

  loc = {
    api: 'http://apidonantes.com.devel',
    wompi: 'https://sandbox.wompi.co/v1',
    redir: 'http://localhost:8080/landing/confirm/transaction/',
    key: 'pub_test_axGe20gTcnJr1FC6nvjbjin17VdvrssR'
  }

  dev = {
    api_new: 'https://apidevdn.unitedwaycolombia.org',
    api: 'https://donantestesting.uwcolombia.org/api/public',
    wompi: 'https://sandbox.wompi.co/v1',
    redir: 'https://donantestesting.uwcolombia.org/landing/confirm/transaction/',
    key: 'pub_test_axGe20gTcnJr1FC6nvjbjin17VdvrssR'
  }

  pro = {
    api: 'https://donantes.uwcolombia.org/backend/public',
    api_new: 'https://apidonaciones.unitedwaycolombia.org',
    wompi: 'https://production.wompi.co/v1',
    redir: 'https://donantes.uwcolombia.org/landing/confirm/transaction/',
    key: 'pub_prod_PyIXRwJ8rGjoqb2IX2msRApdbCGWlwOt'
  }

  params = this.dev;

  setcolours(state){
    const color = {
      APPROVED: 'green',
      PENDING: 'orange',
      DECLINED: 'red',
      published: 'green',
      closed: 'red',
      1: 'green',
      0: 'red',
      default: 'gray'
    }

    return color[state] || color.default;
  }

  moneyformat(value){
    value = parseInt(value);
    if (typeof value !== "number") {
      return value;
    }
    var formatter = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0
    });
    return formatter.format(value);
  }

  formatdate(date){
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  showerror(error){
    swal({
      title: "ERROR",
      text: error.message, 
      icon: "error",
      buttons: {
        text: "ACEPTAR",
      }
    });
  }
  
}